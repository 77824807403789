<template>
  <div>
    <div class="card-toolbar mb-5">
      <button v-if="$can('code_setting.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('code_setting.new_code_setting') }}
      </button>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="type_id">{{ $t('code_setting.type') }}</label>
              <!--                            <select name="" id="type_id" v-model="filters.type_id" type="text" class="form-control" :class="validation && validation.type_id ? 'is-invalid' : ''">>-->
              <!--                                <option :value="null">{{$t('global.all')}}</option>-->
              <!--                                <option v-for="row in type_list" :value="row.id" :key="row.id">{{ row.title }}</option>-->
              <!--                            </select>-->
              <multiselect
                  v-model="type_filter"
                  id="type_id"
                  :placeholder="$t('code_setting.type')"
                  label="title"
                  track-by="id"
                  :options="type_list"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getTypeList($event)"
              >
              </multiselect>
            </div>

            <div class="form-group col-md-6">
              <label for="code_method">{{ $t('status') }}</label>
              <select name="" id="code_method" v-model="filters.code_method" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option value="1">{{ $t('code_setting.automatic') }}</option>
                <option value="2">{{ $t('code_setting.manual') }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{ $t('search') }}</span>
                                </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{ $t('reset_search') }}</span>
                                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">

      <div class="card-body">
        <div>
          <div class="d-flex justify-content-end mb-5">
            <custom-export-data v-if="$can('code_setting.export_data')" :data-list="dataList" :file-name="fileName" :fields="json_fields"></custom-export-data>
          </div>
        </div>
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('code_setting.change_status')"
                size="lg" @change="changeStatus(props.row.id, props.row.is_active)"
                v-model="props.row.is_active"
                :name="'check-button'+props.row.id"
                switch :key="props.row.id">
            </b-form-checkbox>
            <b-form-checkbox v-else
                size="lg" :disabled="true"
                v-model="props.row.is_active"
                :name="'check-button'+props.row.id"
                switch :key="props.row.id">
            </b-form-checkbox>
          </template>
          <template slot="actions" slot-scope="props">
            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('code_setting.update')" color="blue darken-2" @click="showModal(props.row)">mdi-pencil</v-icon>
            <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('code_setting.delete')" color="red darken-2" @click="deletecode_setting(props.row)">mdi-delete</v-icon>
          </template>
        </v-server-table>
        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

    <b-modal ref="modal" hide-footer :title="$t('code_setting.code_setting')">
      <div class="row">
        <div class="col-md-6 mb-5">
          <label for="type">
            {{ $t('code_setting.type') }}<span class="text-danger">*</span>
          </label>
          <!--                <select name="" id="type" v-model="code_setting.type_id" type="text" class="form-control" :class="validation && validation.type_id ? 'is-invalid' : ''">>-->
          <!--                    <option :value="null">{{$t('global.all')}}</option>-->
          <!--                    <option v-for="row in type_list" :value="row.id" :key="row.id">{{ row.title }}</option>-->
          <!--                </select>-->
          <multiselect
              v-model="type"
              id="type"
              :placeholder="$t('code_setting.type')"
              label="title"
              track-by="id"
              :options="type_list"
              :multiple="false"
              :taggable="false"
              :show-labels="false"
              :show-no-options="false"
              :show-no-results="false"
              :disabled="isEditing == true ? true : false"
              @search-change="getTypeList($event)"
          >
          </multiselect>
          <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.type_id[0] }}
                </span>
        </div>
        <div class="col-md-6 mb-5">
          <label for="code_prefix">
            {{ $t('code_setting.code_prefix') }}<span class="text-danger">*</span>
          </label>
          <input v-model="code_setting.code_prefix" id="code_prefix" class="form-control" :class="validation && validation.code_prefix ? 'is-invalid' : ''">
          <span v-if="validation && validation.code_prefix" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.code_prefix[0] }}
                </span>

        </div>
        <div class="col-md-12 mb-5">
          <label for="code_method">
            {{ $t('code_setting.code_method') }}<span class="text-danger">*</span>
          </label>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="code_setting.code_method" :aria-describedby="ariaDescribedby" name="some-radios" value="1">{{ $t('code_setting.automatic') }}</b-form-radio>
            <b-form-radio v-model="code_setting.code_method" :aria-describedby="ariaDescribedby" name="some-radios" value="2">{{ $t('code_setting.manual') }}</b-form-radio>
          </b-form-group>
          <span v-if="validation && validation.code_method" class="fv-plugins-message-container invalid-feedback d-block">
                    {{ validation.code_method[0] }}
                </span>




<!--          <div class="radio-inline">-->
<!--            <label class="radio radio-solid">-->
<!--              <input-->
<!--                  name="code_method"-->
<!--                  v-model="code_setting.code_method"-->
<!--                  type="radio"-->
<!--                  value="1"-->
<!--                  :checked="checkedValue == '1'"-->
<!--              />-->
<!--              <span></span>-->
<!--              {{ $t('code_setting.automatic') }}-->
<!--            </label>-->
<!--            <label class="radio radio-solid">-->
<!--              <input-->
<!--                  name="code_method"-->
<!--                  v-model="code_setting.code_method"-->
<!--                  type="radio"-->
<!--                  value="2"-->
<!--                  :checked="checkedValue == '2'"-->
<!--              />-->
<!--              <span></span>-->
<!--              {{ $t('code_setting.manual') }}-->
<!--            </label>-->
<!--          </div>-->

          <span v-if="validation && validation.code_method" class="fv-plugins-message-container invalid-feedback d-block">
                    {{ validation.code_method[0] }}
                </span>
        </div>
        <div class="col-md-6 mb-5">
          <label for="code_type">
            {{ $t('code_setting.code_type') }}<span class="text-danger">*</span>
          </label>
          <select name="" id="code_type" v-model="code_setting.code_type" type="text" class="custom-select" :class="validation && validation.code_type ? 'is-invalid' : ''">>
            <option :value="null">{{ $t('global.all') }}</option>
            <option v-for="row in code_type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
          </select>

          <span v-if="validation && validation.code_type" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.code_type[0] }}
                    </span>
        </div>
        <div class="col-md-6 mb-5" v-if="show_digit">
          <label for="digit_no">
            {{ $t('code_setting.digit_no') }}<span class="text-danger">*</span>
          </label>
          <input v-model="code_setting.digit_no" type="number" min="1" id="digit_no" class="form-control">
          <span v-if="validation && validation.digit_no" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.digit_no[0] }}
                    </span>
        </div>
        <div class="col-md-6 mb-5" v-if="show_char">
          <label for="char_no">
            {{ $t('code_setting.char_no') }}<span class="text-danger">*</span>
          </label>
          <input v-model="code_setting.char_no" type="number" min="1" id="char_no" class="form-control" :class="validation && validation.char_no ? 'is-invalid' : ''">
          <span v-if="validation && validation.char_no" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.char_no[0] }}
                    </span>
        </div>
        <div class="col-md-6 mb-5">
          <label for="code_start">
            {{ $t('code_setting.code_start') }}<span class="text-danger">*</span>
          </label>
          <input v-model="code_setting.code_start" id="code_start" class="form-control" :class="((validation && validation.code_start) || validation_code_prefix) ? 'is-invalid' : ''">
          <span v-if="validation && validation.code_start" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.code_start[0] }}
                    </span>
          <span v-if="validation_code_prefix" class="fv-plugins-message-container invalid-feedback">
                    {{ validation_code_prefix }}
                </span>
        </div>
        <div class="col-md-6 mb-5">
          <label for="after_update_prefix">
            {{ $t('code_setting.after_update_prefix') }}
          </label>
          <select name="" id="after_update_prefix" v-model="code_setting.after_update_prefix" type="text" class="custom-select" :class="validation && validation.after_update_prefix ? 'is-invalid' : ''">>
            <option :value="null">{{ $t('global.all') }}</option>
            <option v-for="row in after_update_prefix_list" :value="row.id" :key="row.id">{{ row.title }}</option>
          </select>
          <span v-if="validation && validation.after_update_prefix" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.after_update_prefix[0] }}
                </span>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{ $t('close') }}</b-button>
        <b-button class="mt-2 mr-1" variant="primary" @click="save">{{ $t('save') }}</b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index",
  components: {},
  data() {
    return {
      code_setting: {
        type_id: null,
        code_prefix: null,
        code_method: "1",
        code_start: null,
        code_type: null,
        digit_no: null,
        char_no: null,
        after_update_prefix: null,
      },
      showAdvancedSearch: false,
      filter: {
        sortBy: 'id',
      },
      filters: {
        type_id: '',
        code_method: '',
      },
      columns: ['name', 'code_prefix', 'code_method_name', 'created_at', 'actions'],
      validation: null,
      mainRoute: 'settings/code_settings',
      routeChangeStatus: 'settings/code_setting/change-status',
      isEditing: false,
      id: null,
      type_list: [],
      code_type_list: [],
      after_update_prefix_list: [],
      checkedValue: "",
      dataList: [],
      validation_code_prefix: '',
      ready_to_save: false,
      show_digit: true,
      show_char: true,
      type_filter: null,
      type: null,

    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('code_setting.type')] = 'type_id';
      fields[this.$t('code_setting.code_prefix')] = 'code_prefix';
      fields[this.$t('code_setting.code_method')] = 'code_method_name';
      fields[this.$t('created_at')] = 'created_at';
      return fields;
    },
    fileName: function () {
      return this.$t('code_setting.code_setting');
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          name: that.$t('code_setting.type'),
          code_prefix: that.$t('code_setting.code_prefix'),
          code_method_name: that.$t('code_setting.code_method'),
          created_at: that.$t('created_at'),
          actions: that.$t('actions'),
        },
        sortable: ['type_id'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',
        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }
    },
  },
  watch: {
    'code_setting.code_start': function (val) {
      if (val) {
        this.checkCodePrefix();
      }
    },
    'code_setting.code_type': function (val) {
      if (val) {
        this.checkCodePrefix();
      }
    },
    'code_setting.digit_no': function (val) {
      if (val) {
        this.checkCodePrefix();
      }
    },
    'code_setting.char_no': function (val) {
      if (val) {
        this.checkCodePrefix();
      }
    },
    type_filter: function (val) {
      if (val) {
        this.filters.type_id = val.id;
      } else {
        this.filters.type_id = null;
      }
    },
    type: function (val) {
      if (val) {
        this.code_setting.type_id = val.id;
      } else {
        this.code_setting.type_id = null;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.code_setting_management")}]);
    this.getTypeList();
    this.getCodeTypeList();
    this.getAfterUpdatePrefixList();
  },
  methods: {
    changeStatus(id, status) {
      ApiService.patch(this.routeChangeStatus + '/' + id, {
        is_active: (status ? 1 : 0),
      }).then((response) => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch((errors) => {
        this.$errorAlert(errors);
      });
    },

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.type_id = '';
      this.filters.code_method = '';
      this.type_filter = null;

      this.$refs.table.refresh();
    },
    save() {
      this.checkCodePrefix();

      if (!this.ready_to_save) {
        return false;
      }
      if (this.isEditing) {
        this.updateCodeSetting();
      } else {
        this.saveCodeSetting();
      }
    },
    getFetch() {
      this.$refs.table.refresh();
    },
    actionDelete(code_setting) {

      ApiService.delete(`${this.mainRoute}/${code_setting.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((errors) => {
        this.$errorAlert(errors);
      })
    },
    deletecode_setting(code_setting) {
      this.$confirmAlert('', this.actionDelete, code_setting);
    },

    showModal(data) {
      if (!data.id) {
        this.reset();
        this.$refs['modal'].show()
      } else {
        this.isEditing = true;
        this.id = data.id;
        this.getData(data.id);
        this.$refs['modal'].show();
      }
    },
    hideModal() {
      this.reset();
      this.$refs['modal'].hide()
    },
    toggleModal() {
      this.$refs['modal'].toggle('#toggle-btn')
    },
    saveCodeSetting() {
      ApiService.post(this.mainRoute, this.code_setting).then((response) => {
        this.$successAlert(response.data.message);
        this.aftersaveCodeSetting();
      }).catch((errors) => {
        this.$errorAlert(errors);
        this.validation = errors.response ? errors.response.data.errors : null;
      });
    },
    updateCodeSetting() {
      ApiService.put(`${this.mainRoute}/${this.id}`, {
        ...this.code_setting,
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.validation = null;
        this.aftersaveCodeSetting();
      }).catch((errors) => {
        this.$errorAlert(errors);
        this.validation = errors.response ? errors.response.data.errors : null;
      });
    },

    getData(id) {
      ApiService.get(`${this.mainRoute}/${id}`).then((response) => {
        this.isEditing = true;

        this.code_setting.type_id = response.data.data.type_id;
        this.code_setting.code_prefix = response.data.data.code_prefix;
        this.code_setting.code_method = response.data.data.code_method;
        this.code_setting.code_start = response.data.data.code_start;
        this.code_setting.code_type = response.data.data.code_type;
        this.code_setting.digit_no = response.data.data.digit_no;
        this.code_setting.char_no = response.data.data.char_no;
        this.code_setting.after_update_prefix = response.data.data.after_update_prefix;
        this.type = response.data.data.type;
      });
    },

    reset() {
      this.code_setting = {
        type_id: '',
        code_prefix: '',
        code_method: "1",
        code_start: '',
        code_type: '',
        digit_no: '',
        char_no: '',
        after_update_prefix: '',
      };
      this.validation = [];
      this.isEditing = false;
      this.type = null;
    },

    getTypeList(filter) {
      ApiService.get('base/dependency/code_settings_type_id').then(response => {
        this.type_list = response.data.data;
      });
    },
    getCodeTypeList() {
      ApiService.get('base/dependency/code_type_settings').then(response => {
        this.code_type_list = response.data.data;
      });
    },
    getAfterUpdatePrefixList() {
      ApiService.get('base/dependency/after_update_prefix').then(response => {
        this.after_update_prefix_list = response.data.data;
      });
    },

    aftersaveCodeSetting() {
      this.code_setting = {
        name: '',
        abbreviation: '',
        sub_value_total: '',
        notes: '',
      };
      this.hideModal();
      this.getFetch();
    },
    checkCodePrefix() {
      let _message = '';
      this.ready_to_save = true;
      let reg_structure = null;

      // numeric digits
      if (this.code_setting.code_type == 1) {
        this.show_digit = true;
        this.show_char = false;
        reg_structure = '^[0-9]{' + this.code_setting.digit_no + '}$';
        let reg = new RegExp(reg_structure)
        if (!(reg.test(this.code_setting.code_start))) {
          _message = this.$t('validation.numeric_digits');
          this.ready_to_save = false;
        }
      }
      // lowercase hex numbers
      if (this.code_setting.code_type == 2) {
        this.show_digit = true;
        this.show_char = true;
        let _digit_no = this.code_setting.digit_no ? parseInt(this.code_setting.digit_no) : 0;
        let _char_no = this.code_setting.char_no ? parseInt(this.code_setting.char_no) : 0;
        let _length = _digit_no + _char_no;

        reg_structure = '^#?([a-f0-9]{' + _length + '}|[a-f0-9]{' + _length + '})$';
        let reg = new RegExp(reg_structure)
        if (!(reg.test(this.code_setting.code_start))) {
          _message = this.$t('validation.lowercase_hex_numbers');
          this.ready_to_save = false;
        }
      }

      // uppercase hex numbers
      if (this.code_setting.code_type == 3) {
        this.show_digit = true;
        this.show_char = true;
        let _digit_no = this.code_setting.digit_no ? parseInt(this.code_setting.digit_no) : 0;
        let _char_no = this.code_setting.char_no ? parseInt(this.code_setting.char_no) : 0;
        let _length = _digit_no + _char_no;
        reg_structure = '^#?([A-F0-9]{' + _length + '}|[A-F0-9]{' + _length + '})$';
        let reg = new RegExp(reg_structure)
        if (!(reg.test(this.code_setting.code_start))) {
          _message = this.$t('validation.uppercase_hex_numbers');
          this.ready_to_save = false;
        }
      }

      // lowercase letters
      if (this.code_setting.code_type == 4) {
        this.show_digit = false;
        this.show_char = true;
        reg_structure = '^[a-z]{' + this.code_setting.char_no + '}$';
        let reg = new RegExp(reg_structure)
        if (!(reg.test(this.code_setting.code_start))) {
          _message = this.$t('validation.lowercase_letters');
          this.ready_to_save = false;
        }
      }
      // uppercase letters
      if (this.code_setting.code_type == 5) {
        this.show_digit = false;
        this.show_char = true;
        reg_structure = '^[A-Z]{' + this.code_setting.char_no + '}$';
        let reg = new RegExp(reg_structure)
        if (!(reg.test(this.code_setting.code_start))) {
          _message = this.$t('validation.uppercase_letters');
          this.ready_to_save = false;
        }
      }
      // lowercase letters followed by numeric
      if (this.code_setting.code_type == 6) {
        this.show_digit = true;
        this.show_char = true;
        reg_structure = '^[a-z]{' + this.code_setting.char_no + '}[0-9]{' + this.code_setting.digit_no + '}$';
        let reg = new RegExp(reg_structure)
        if (!(reg.test(this.code_setting.code_start))) {
          _message = this.$t('validation.lowercase_letters_followed_by_numeric_digits');
          this.ready_to_save = false;
        }
      }
      // uppercase letters followed by numeric digits
      if (this.code_setting.code_type == 7) {
        this.show_digit = true;
        this.show_char = true;
        reg_structure = '^[A-Z]{' + this.code_setting.char_no + '}[0-9]{' + this.code_setting.digit_no + '}$';
        let reg = new RegExp(reg_structure)
        if (!(reg.test(this.code_setting.code_start))) {
          _message = this.$t('validation.uppercase_letters_followed_by_numeric_digits');
          this.ready_to_save = false;
        }
      }
      this.validation_code_prefix = _message;
    }
  },
};
</script>
